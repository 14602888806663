import { SelectInput, SelectInputOptionContent } from '@transferwise/components';
import React from 'react';

import styles from './Select.module.scss';
import { useSelectContext } from './SelectContext';

type Props = {
  children: React.ReactNode;
  title: string;
  placeholder: string;
  options: Option[];
};

type Option = {
  label: string;
  value: string;
};

const emptyOptions: Option[] = [];

export const Select = ({ children, placeholder = '', options = emptyOptions }: Props) => {
  const { selectOption } = useSelectContext();

  return (
    <>
      <div className={styles.select}>
        <SelectInput
          placeholder={placeholder}
          items={options.map((o) => ({
            type: 'option',
            value: o,
          }))}
          className={styles.select}
          renderValue={(o) => <SelectInputOptionContent title={o.label} />}
          onChange={selectOption}
        />
      </div>
      {children}
    </>
  );
};
