import styles from './Video.module.scss';

type Props = {
  src: string;
};

export const Video = ({ src }: Props) => (
  // eslint-disable-next-line jsx-a11y/media-has-caption
  <video width="100%" controls className={styles.video}>
    <source src={src} type="video/mp4" />
  </video>
);
