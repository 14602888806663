import classNames from 'classnames';

import styles from './Heading.module.scss';
import { JSX } from 'react';

type Props = {
  level: number;
  children: string;
  id?: string;
  className?: string;
  inline?: boolean;
};

export const Heading = (props: Props) => {
  const { id, inline = false } = props;
  if (!id) {
    return <HeadingElement {...props} />;
  }

  if (inline) {
    return (
      <a href={`#${id}`} className="d-inline-block text-no-decoration">
        <HeadingElement {...props} />
      </a>
    );
  }

  return (
    <div className={styles.container}>
      <a href={`#${id}`} className="d-inline-block text-no-decoration">
        <HeadingElement {...props} />
      </a>
    </div>
  );
};

const HeadingElement = ({ level, id, children, className }: Props) => {
  const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
  return (
    <HeadingTag id={id} className={classNames(id ? styles.headingAsLink : null, className)}>
      {children}
    </HeadingTag>
  );
};
