import React from 'react';

import { useSelectContext } from './SelectContext';

type Props = {
  children: React.ReactNode;
  value: string;
};

// eslint-disable-next-line @typescript-eslint/promise-function-async
export const Option = ({ children, value }: Props) => {
  const { selected } = useSelectContext();
  if (selected.value !== value) {
    return null;
  }
  return children;
};
