import { Link } from '@transferwise/components';

import { track } from '../../helpers/tracking';

import styles from './PostmanRunButton.module.scss';

type Props = {
  collectionId: string;
  workspaceId: string;
};

/*
 * Provide collectionId && workspaceId as parameters for directly forking a collection
 * The user will be routed directly to postman with the ability to fork
 */
export const PostmanRunButton = ({ collectionId, workspaceId }: Props) => {
  const url = `https://elements.getpostman.com/view/fork?collection=${collectionId}&workspaceId=${workspaceId}`;
  return (
    <Link
      className={styles.link}
      href={url}
      rel="noreferrer"
      target="_blank"
      onClick={() => track('Postman button click')}
    >
      Fork in Postman
    </Link>
  );
};
