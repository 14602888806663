type Field = {
  name: string;
  format: string;
  description: string;
  required?: 'yes' | 'no' | 'conditionally';
};

type FieldMapping = {
  name: PropMapping;
  format: PropMapping;
  description: PropMapping;
};

type PropMapping = {
  index: number;
  fn: (v: string) => string;
};

const defaultMapping = {
  name: { index: 0, fn: (v: string) => v },
  description: { index: 1, fn: (v: string) => v },
  format: { index: 2, fn: (v: string) => v.toLocaleLowerCase() },
};

export function convertMarkdownTableToMarkDocFieldSet({
  markdownTable,
  title,
  mapping,
}: {
  markdownTable: string;
  title?: string;
  mapping?: FieldMapping;
}): string {
  const [_, separatorRow, ...dataRows] = markdownToRows(markdownTable);
  assert(separatorRow.every((cell) => cell.split('').every((char) => char === '-')));

  return renderDataRowsAsMarkDocFieldSet({ dataRows, mapping, title });
}

export function renderDataRowsAsMarkDocFieldSet({
  dataRows,
  title,
  mapping,
}: {
  dataRows: string[][];
  title?: string;
  mapping?: FieldMapping;
}) {
  const fieldMapping = mapping ?? defaultMapping;
  const fields = dataRows.map((row) => makeField(row, fieldMapping));
  return renderFieldsToMarkDoc(fields, title || 'Title');
}

function markdownToRows(markdownTable: string): string[][] {
  const lines = getNonEmptyLines(markdownTable);
  return lines.map((line) => line.split('|').map((cell) => cell.trim()));
}

function getNonEmptyLines(s: string) {
  return s
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => !!line);
}

function makeField(row: string[], mapping: FieldMapping): Field {
  return {
    name: mapping.name.fn(row[mapping.name.index]),
    description: mapping.description.fn(row[mapping.description.index]),
    format: mapping.format.fn(row[mapping.format.index]),
  };
}

//

function renderFieldsToMarkDoc(fields: Field[], title: string): string {
  return `{% fields title="${title}" %}
${fields.map(renderFieldToMarkDoc).join('')}{% /fields %}`;
}

function renderFieldToMarkDoc({ name, description, format }: Field): string {
  return `{% field name="${name}" format="${format}" %}
${description}
{% /field %}
`;
}

// ////

function assert(condition: boolean) {
  if (!condition) {
    throw new Error('oh no');
  }
}
