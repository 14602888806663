import { useState } from 'react';

import { convertMarkdownTableToMarkDocFieldSet } from '.';

const textAreaStyle = { width: '100%', height: '15em', fontFamily: 'monospace', fontSize: '0.9em' };

export const TableToFieldset = () => {
  const [title, setTitle] = useState('');
  const [markdownTable, setMarkdown] = useState(placeholderTable);

  let fieldset = '';
  try {
    fieldset = convertMarkdownTableToMarkDocFieldSet({ markdownTable, title });
  } catch {
    fieldset = `ERROR!\nPlease, make sure you entered a valid Markdown table.\nFor example:\n${basicTable}`;
  }
  return (
    <div>
      <div>
        <strong>Step 1: paste your markdown table here</strong>

        <input
          style={{ width: '100%', padding: '0.25em', marginBottom: '0.25em' }}
          value={title}
          placeholder="(optionally, enter a title here)"
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          style={textAreaStyle}
          value={markdownTable}
          onChange={(e) => setMarkdown(e.target.value)}
        />
      </div>
      <div>
        <strong>Step 2: copy the markdoc fields below:</strong>
        <textarea style={textAreaStyle} value={fieldset} readOnly />
        <strong>Step 3: paste them in your markdoc file</strong>
      </div>
    </div>
  );
};

const placeholderTable = `
Field                 | Description                                   | Format
---------             | -------                                       | --------
id                    | Some id. I don't know!                        | Integer
targetAccount         | Some account. *Italic* should be supported.   | Integer
quoteUuid             | V2 quote id. **Bold should be supported**.    | Text
customerTransactionId | [Links](#somewhere) should also be supported. | UUID

`;

const basicTable = `
Field                 | Format
---------             | --------
id                    | Integer
quoteUuid             | Text
`;
