const MAX_SENTENCE_LENGTH = 250;

export const getExcerpts = (markdownText: string, excerpt?: string) => {
  if (excerpt) {
    return excerpt;
  }
  const sentenceArray = markdownText.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|');
  const descSentence = sentenceArray.slice(0, 2).join(' ');
  return descSentence.length > MAX_SENTENCE_LENGTH
    ? `${descSentence.slice(0, MAX_SENTENCE_LENGTH - 1)}...`
    : descSentence;
};
