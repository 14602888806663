import classNames from 'classnames';
import Highlight, { defaultProps, Language } from 'prism-react-renderer';

import { theme } from './theme';

type Props = {
  children: string;
  language?: Language;
};

export const Fence = ({ children, language = 'bash' }: Props) => (
  <Highlight
    {...defaultProps}
    theme={theme}
    // eslint-disable-next-line regexp/no-super-linear-move
    code={children.replace(/\n*$/, '')} // removing trailing newline to avoid empty line in output
    language={language}
  >
    {({ className, tokens, getLineProps, getTokenProps }) => (
      <pre className={classNames(className, 'bg-elevated', 'p-a-0', 'm-a-0')}>
        {tokens.map((line, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={key} {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </pre>
    )}
  </Highlight>
);
